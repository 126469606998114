import React from "react"
import Hero from "../../components/hero-team"
import Staff from "../../components/staff"
import "../../assets/index.css"
import Layout from "../../layouts/layout"

export default () => (
    <Layout title="Team | Ladd Partners"> 
        <Hero/>
        <Staff></Staff>
    </Layout>
)
