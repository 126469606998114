import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import ReactModal from 'react-modal'

ReactModal.setAppElement('body')

const StaffContainerDiv = styled.div`
    width: 33%;
    max-width: 312px;
    padding: 0 20px;
    margin-bottom: 60px;

    @media only screen and (max-width: 1024px) {
        width: 50%;
        margin-bottom: 40px;
        padding: 0;

        h3 {
            font-size: 16px;
            line-height: 24px;
        }
    }
`;

const StaffContentDiv = styled.div`
    height: 100%;
    cursor: pointer;
`;

const StaffImageDiv = styled.div`
    margin: auto;
    height: 297px;
    border: 4px solid #E6ECF1;
    border-radius: 50%;
    background: #E6ECF1;
    width: 297px;

    .gatsby-image-wrapper {
        margin-left: 4px!important;
        margin-top: 2px!important;
    }

    :hover {
        box-shadow: rgba(0, 158, 219, 0.8) 0px 0px 20px 0px;
        transition: box-shadow .8s ease-in-out;
    }

    @media only screen and (max-width: 1024px) {
        height: 145px;
        width: 145px;

        .gatsby-image-wrapper {
            width: 137px!important;
            height: 137px!important;
            margin-top: 4px!important;
        }
    }
`;

const StaffModalImageDiv = styled.div`
    margin: auto;
    height: 297px;
    border: 4px solid #E6ECF1;
    border-radius: 50%;
    background: #E6ECF1;
    width: 297px;
    margin-top: -25%;

    .gatsby-image-wrapper {
        margin-left: 4px!important;
        margin-top: 2px!important;
    }

    @media only screen and (max-width: 600px) {
        height: 172px;
        width: 172px;

        .gatsby-image-wrapper {
            width: 164px!important;
            height: 164px!important;
            margin-top: 4px!important;
        }
    }
`;

const StaffModalDiv = styled.div `
    max-height: 500px;
    overflow: auto;
    @media only screen and (max-width: 600px) {
        max-height: 300px;
    }
`
const StaffCloseModalLink = styled.a `
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`

const closeSVG = () => (
    <svg width="27px" height="28px" viewBox="0 0 27 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" strokeLinecap="round">
            <g id="ABOUT-/-Profile-Card" transform="translate(-506.000000, -19.000000)" stroke="#3D7BB2" stroke-width="3">
                <g id="Group" transform="translate(506.894034, 21.000000)">
                    <path d="M1,0.5 L23.7184681,23.2184681" id="Line-3"></path>
                    <path d="M1,0.5 L23.7184681,23.2184681" id="Line-3-Copy" transform="translate(12.500000, 12.000000) rotate(88.000000) translate(-12.500000, -12.000000) "></path>
                </g>
            </g>
        </g>
    </svg>
)

class StaffCard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            modalIsOpen: false
        };

      }
      handleModalOpen = event => {
        this.setState({ isModalOpen: true })
      }

      handleModalClose = event => {
        this.setState({ isModalOpen: false })
      }
      render() {
        return (
            <StaffContainerDiv>
            <StaffContentDiv onClick={this.handleModalOpen}>
                <StaffImageDiv>
                    <Img fixed={this.props.staff.image.asset.fixed}/>
                </StaffImageDiv>
                <h3 className="margin-top-20 text-center">{this.props.staff.first_name} {this.props.staff.last_name}</h3>
            </StaffContentDiv>
            <ReactModal isOpen={this.state.isModalOpen}
                        className="Modal"
                        overlayClassName="Overlay"
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={() => {
                            this.setState({ isModalOpen: false });
                        }} >
                <StaffModalImageDiv>
                    <Img fixed={this.props.staff.image.asset.fixed}/>
                </StaffModalImageDiv>
                <h3>{this.props.staff.first_name} {this.props.staff.last_name} </h3>
                <StaffModalDiv>
                    <p>{this.props.staff.bio}</p>
                </StaffModalDiv>
                <StaffCloseModalLink onClick={this.handleModalClose}>
                    {closeSVG()}
                </StaffCloseModalLink>

            </ReactModal>
            </StaffContainerDiv>
        )
      }
}

export default StaffCard