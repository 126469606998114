import React from "react";
import { StaticQuery, graphql } from "gatsby"
import HeroBase from "../components/hero-base"

const query = graphql`
    query {
        sanityHero(title: { eq: "Team" }) {
            title
           heading
            subline
            video_url
            background_color
            image {
                asset {
                    url
                }
            }
            background_image {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
              }
          }
      }
  `

function getHero(data) {
    if(data && data.sanityHero == null){
        return;
    };
    return <HeroBase hero={data.sanityHero}></HeroBase>
}

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>{getHero(data)}</>
            )
        }}
    />
)