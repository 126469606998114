import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import StaffCard from "../components/staff-card"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 40px auto 40px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    h2 {
        width: 100%;
        margin: 65px 0 40px;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const DetailFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #E5F5FB;
    margin-bottom: 0;

    h2 {
        width: 100%;
        margin: 65px 0 40px;
    }

    @media only screen and (max-width: 1140px) {
        padding: 0 20px;
        margin-bottom: 60px;
    }
`;

const query = graphql`
    query {
        allSanityStaff(sort: {fields: [last_name], order: ASC}) {
            edges {
              node {
                id
                first_name
                last_name
                bio
                isFeatured
                image {
                    asset {
                        fixed(width: 290, height: 290) {
                            ...GatsbySanityImageFixed_noBase64
                        }
                    }
                }
              }
            }
          }
      }
  `

function getStaff(data, featured) {
    const staffArray = [];
    data.allSanityStaff.edges.forEach(item => {
        if (featured && item.node.isFeatured){
            staffArray.push(<StaffCard staff={item.node} key={item.node.id}></StaffCard>)
        } else if(!featured && !item.node.isFeatured && item.node.image != null) {
            staffArray.push(<StaffCard staff={item.node} key={item.node.id}></StaffCard>)
        }
    });
    return staffArray;
}

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <DetailFlex>
                    <h2 className="text-center">Partners</h2>
                    {getStaff(data, true)}
                </DetailFlex>
                <WrapperDiv>
               <Flex>
               <    h2 className="text-center">Our Team</h2>
                    {getStaff(data, false)}
                </Flex>
                </WrapperDiv>
                </>
            )
        }}
    />
)
